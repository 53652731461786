<template>
    <div id="task-log" class="task-log">
        <sn-table-group :tableGroupAttributes="commonData" commonMethods="" @handleChange="handleChange"></sn-table-group>
    </div>
</template>

<script>
import { commonData } from './sn-table-group-common';
import { mapActions } from 'vuex';
export default {
    name: 'task-log',
    data() {
        return {
            commonData,
            keyword: '',
            bdlx: '',
            bmid: '',
        };
    },
    mounted() {
        // 获取表格数据
        this.commonData.snPage.currentPage = 1;
        this.getTableData();
        // 获取变动类型列表
        this.getEnumItem();
        // 获取部门列表
        this.getDepList();
    },
    methods: {
        ...mapActions([
            'GetXcglList', // 获取巡查管理列表
            'GetEnumItem', // 获取变动类型枚举信息
            'getDepNameList', // 获取部门列表
        ]),
        handleChange(type, value) {
            switch (type) {
                case 'search': // 筛选条件
                    this[value[1]] = value[0];
                    this.commonData.snSearch.selectData.forEach(item => {
                        if (item.type === value[1]) {
                            item.value = value[0];
                        }
                    });
                    this.commonData.snPage.currentPage = 1;
                    this.getTableData();
                    break;
                case 'total': // 每页条数
                    this[value[1]] = value[0];
                    this.commonData.snPage.tiaoshu = value[0];
                    this.commonData.snPage.currentPage = 1;
                    this.getTableData();
                    break;
                case 'page': // 当前是第几页
                    this.commonData.snPage.currentPage = value[0];
                    this.getTableData();
                    break;
            }
        },
        async getTableData() {
            this.commonData.snTable.table.data = [];
            let page = this.commonData.snPage.tiaoshu;
            let da = await this.GetXcglList({
                keyword: this.keyword,
                bdlx: this.bdlx,
                depmentid: this.bmid,
                pageIndex: this.commonData.snPage.currentPage,
                pageSize: page,
            });
            this.commonData.snTable.table.data = da.RESULT;
            this.commonData.snPage.count = Number(da.COUNT);
            this.commonData.loading = false;
        },
        // 获取变动类型列表
        async getEnumItem() {
            let da = await this.GetEnumItem({
                Enumid: 'f6244b75-a7c6-11eb-b3ce-000c2977b7fd'
            });
            let selectData = this.commonData.snSearch.selectData.filter(item => {
                return item.value === 'bdlx';
            });
            selectData[0].list = da.filter(item => { // TODO
                return item.Name !== null;
            });
        },
        // 获取部门列表
        async getDepList() {
            let da = await this.getDepNameList({
                czqx: ''
            });
            let selectData = this.commonData.snSearch.treeselectData.filter(item => {
                return item.value === 'bmid';
            });
            selectData[0].list = da.filter(item => { // TODO
                return item.NAME !== null;
            });
        },
    }
};
</script>
<style lang="scss" scoped>
.footer {
    border-top: 1px dashed #BACBDF;
    text-align: right;
    padding-top: 20px;
    &.el-form-item {
        margin-bottom: 5px;
    }
}
</style>