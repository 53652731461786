const commonData = {
    loading: false,
    snTable: {
        table: {
            data: [],
            rowKey: 'id',
            treeProps: {
                children: 'children',
                hasChildren: 'hasChildren'
            },
        },
        columns: {
            // 选择、序号
            columnsOperate: [{
                isShow: true,
                label: '序号',
                type: 'index',
                width: 50
            }],
            // 数据列
            columnsData: [{
                prop: 'CJSJ',
                label: '时间',
                substr: true,
                minWidth: 100
            }, {
                prop: 'JCPZMC',
                label: '任务名称',
                minWidth: 180
            }, {
                prop: 'BDLXMC',
                label: '变动类型',
                minWidth: 100,
            }, {
                prop: 'CJRDEPMC',
                label: '操作部门',
                minWidth: 100
            }, {
                prop: 'CJRMC',
                label: '操作人',
                minWidth: 100
            }],
            // 操作列
            // columnsBtn: {
            //     width: '200',
            //     buttonData: [{
            //         isShow: true,
            //         // showMethod: '',
            //         btnType: 'img',
            //         operateType: 'columnInfo',
            //         title: '查看详情',
            //         src: 'monitoring_cloud/detail.png',
            //         class: 'four',
            //     }]
            // }
        },
    },
    snPage: {
        tiaoshu: 20,
        count: 0,
        currentPage: 1,
        operateType: 'page'
    },
    // snCount: {
    //     eachtotal: [10, 20, 50, 100],
    //     defaultTiaoshu: 10
    // },
    snSearch: {
        inputData: [{
            // label: '关键字',
            placeholder: "关键字",
            value: 'keyword',
            operateType: 'search',
            isShow: true
        }],
        selectData: [{
            // label: '变动类型',
            clearable: true,
            placeholder: "变动类型",
            class: 'selectRound',
            list: [],
            optionLabel: 'Name',
            optionValue: 'Code',
            value: 'bdlx',
            operateType: 'search',
            isShow: true
        }],
        treeselectData: [{
            clearable: true,
            placeholder: "部门",
            list: [],
            optionLabel: 'NAME',
            optionValue: 'ID',
            value: 'bmid',
            operateType: 'search',
            isShow: true,
            searchable: true,
            class: "selectRound",
            disableBranchNodes: false,
            width: '160px',
        }],
        // searchBtn: {
        //     buttonData: [{
        //         isShow: true,
        //         btnType: 'dropdown',
        //         dropdownClass: 'roundDropdown',
        //         menuList: [],
        //         operateType: 'buttonNew',
        //         name: '新增',
        //         round: true,
        //         // beforeIcon: 'el-icon-edit',
        //         afterIcon: 'el-icon-delete',
        //         backColor: '#2A63D6',
        //         // color: '#fff'
        //     }, {
        //         btnType: 'button',
        //         operateType: 'buttonAllot',
        //         name: '批量分配',
        //         round: true,
        //         backColor: '#2A63D6',
        //         color: '#fff'
        //     }]
        // }
    }
};
export { commonData };